import errorCode, { errorCodeType } from '@/api/types/errorCode'
import { getTransitionLanguage } from '@/locales'

export const errorCodeLocales = (code: string): string => {
  const language = getTransitionLanguage()
  const message = errorCode.find(item => item.code === code) as errorCodeType

  // if (!message) {
  //   return 'Error message not found' // 处理找不到错误码的情况
  // }

  const languageMap: { [key: string]: string } = {
    zh: message.message,
    'zh-Hant': message.zhHantMessage,
    ar: message.arMessage,
    id: message.idMessage,
    in: message.idMessage, // 支持 'in' 和 'id' 使用相同的消息
    tl: message.tlMessage,
    ms: message.msMessage
  }

  for (const [lang, msg] of Object.entries(languageMap)) {
    if (language.includes(lang)) {
      return msg
    }
  }

  return message.enMessage // 默认返回英文消息
}
