
import { computed, defineAsyncComponent, PropType, Ref, ref, watchEffect } from 'vue'
import { Tab, Tabs, Overlay, Icon } from 'vant'
import { getTransitionLanguage } from '@/locales'
import LangSelect from './lang-select/index.vue'
import { languageList } from './lang-select/data'
import { SelectTypeItem } from '@/types/common'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

interface IHeaderState {
  headerBackground: Ref<string>
  headerTabActive: Ref<number | string>
  hamburgerActive: Ref<boolean>
  onClickHamburger: () => void
  nameLogo: Ref<string>
  language: Ref<string>
  lang: Ref<string>
  languageList: SelectTypeItem[]
  onClickOverlay: () => void
  langSelectActive: Ref<boolean>
}

export interface IHeaderTabItem {
  title: string | number
  to: string
  name?: string | number
  isShowMobile?: boolean
}

export default {
  components: {
    [Tab.name as string]: Tab,
    [Tabs.name as string]: Tabs,
    [Overlay.name as string]: Overlay,
    [Icon.name as string]: Icon,
    MHamburger: defineAsyncComponent(() => import('@/components/hamburger/index.vue')),
    LangSelect
  },
  props: {
    background: {
      type: String as PropType<string>,
      default: ''
    },
    hamburgerColor: {
      type: String as PropType<string>
    },
    isShowHamburger: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    headerLogo: {
      type: String as PropType<string>,
      default: ''
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: 'logo-black.png'
    },
    isShowTabs: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    tabActive: {
      type: [String, Number] as PropType<string | number>,
      default: ''
    },
    tabColor: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    titleInactiveColor: {
      type: String as PropType<string>,
      default: 'rgba(255, 255, 255, 0.4)'
    },
    titleActiveColor: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    headerTabList: {
      type: Array as PropType<IHeaderTabItem[]>,
      default: () => []
    },
    qrCodeLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    qrCodeUrl: {
      type: String as PropType<string>,
      default: window.location.href
    },
    headerBorderBottom: {
      type: String as PropType<string>,
      default: '1px solid rgba(47, 47, 47, 0.2)'
    }
  },
  setup(props: Readonly<{
    background: string
    tabActive: string | number
    headerNameLogo: string
  }>): IHeaderState {
    const hamburgerActive = ref<boolean>(false)
    const headerTabActive = ref<string | number>('')
    const nameLogo = ref<string>('')
    const headerBackground = ref<string>('')
    const lang = ref<string>('')
    const langSelectActive = ref<boolean>(false)

    watchEffect(() => {
      headerBackground.value = props.background
    })

    watchEffect(() => {
      headerTabActive.value = props.tabActive
    })

    watchEffect(() => {
      nameLogo.value = props.headerNameLogo
    })

    const language = computed(() => {
      return getTransitionLanguage()
    })

    useWindowSizeFn(
      () => {
        if (window.innerWidth > 825) {
          hamburgerActive.value = false
        }
      },
      200,
      { immediate: true }
    )

    const onClickHamburger = () => {
      hamburgerActive.value = !hamburgerActive.value
      nameLogo.value = hamburgerActive.value ? 'logo-white.png' : props.headerNameLogo
      headerBackground.value = hamburgerActive.value ? 'none' : props.background
    }

    const onClickOverlay = () => {
      hamburgerActive.value = langSelectActive.value || false
    }

    return {
      headerBackground,
      headerTabActive,
      hamburgerActive,
      onClickHamburger,
      onClickOverlay,
      lang,
      nameLogo,
      language,
      languageList,
      langSelectActive
    }
  }
}
