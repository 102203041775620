<template>
  <div v-if="isMobile">
    <span class="meta-dropdown__value" @click="show = true">
      <template v-if="!isSlots">
        <span class="meta-dropdown__text" :style="{ color: textColor }">{{ value ? $findLabel(data, value) : '选择'
          }}</span>
        <van-icon :name="require('@/assets/img/recharge/down-arrow.png')" size="1.6em">
        </van-icon>
      </template>
      <slot v-else name="content"></slot>
    </span>
    <van-popup :teleport="teleport" class="meta-dropdown__popup" :z-index="zIndex" v-model:show="show" round
      position="bottom" :safe-area-inset-bottom="true">
      <van-picker class="meta-dropdown__picker" :confirm-button-text="$t('popup.confirm')"
        :cancel-button-text="$t('popup.cancel')" :columns="data" :columns-field-names="customFieldName"
        :default-index="defaultIndex" @cancel="handleCancel" @confirm="(value) => handleConfirm(value)" />
    </van-popup>
  </div>
  <van-popover v-else v-model:show="show" :teleport="teleport" :placement="placement" class="meta-dropdown__popover"
    :style="{ zIndex: zIndex }">
    <div class="meta-dropdown__select">
      <span class="meta-dropdown__select--content"
        :class="{ 'meta-dropdown__select--active': defaultIndex === index }" v-for="( item, index ) in  data "
        :key="index" @click="handleSelect(item, index)"> {{ item.label }}</span>
    </div>
    <template #reference>
      <span class="meta-dropdown__value">
        <template v-if="!isSlots">
          <span class="meta-dropdown__text" :style="{ color: textColor }">{{ value ? $findLabel(data, value) : '选择'
            }}</span>
          <van-icon :name="require('@/assets/img/recharge/down-arrow.png')" size="1.6em">
          </van-icon>
        </template>
        <slot v-else name="content"></slot>
      </span>
    </template>
  </van-popover>
</template>

<script lang='ts'>
import { reactive, toRefs, PropType, defineComponent, watchEffect, watch, getCurrentInstance, ComponentInternalInstance } from 'vue'
import { IDropdownState, metaDropdownEmits } from './data'
import { Popup, Picker, Popover, Icon } from 'vant'
import { SelectTypeItem } from '@/types/common'
import { isMobile } from '@/utils/is'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

export default defineComponent({
  name: 'MetaDropdown',
  components: {
    [Popup.name as string]: Popup,
    [Picker.name as string]: Picker,
    [Icon.name as string]: Icon,
    [Popover.name as string]: Popover
  },
  emits: metaDropdownEmits,
  props: {
    modelValue: {
      type: [String, null] as PropType<string>,
      default: null
    },
    data: {
      type: Array as PropType<SelectTypeItem[]>,
      default: [] as SelectTypeItem[]
    },
    teleport: {
      type: String as PropType<string>,
      default: '#main'
    },
    placement: {
      type: String as PropType<string>,
      default: 'left-start'
    },
    textColor: {
      type: String as PropType<string>,
      default: '#262928'
    },
    zIndex: {
      type: [Number, String] as PropType<number | string>,
      default: 2006
    }
  },
  setup(props, { emit }) {
    const { slots } = getCurrentInstance() as ComponentInternalInstance
    const state = reactive<IDropdownState>({
      show: false,
      value: '',
      customFieldName: {
        text: 'label',
        value: 'value'
      },
      defaultIndex: 0,
      isMobile: false,
      isSlots: false
    })

    watchEffect(() => {
      state.value = props.modelValue
    })

    watch(() => state.show, () => {
      state.defaultIndex = props.data.findIndex((item) => item.value === state.value)
    }, { immediate: true })

    watch(() => slots?.content, (val) => {
      if (val) {
        state.isSlots = true
        console.log(state.isSlots)
      }
    }, { immediate: true })

    useWindowSizeFn(
      () => {
        state.isMobile = isMobile()
      },
      100,
      { immediate: true }
    )

    const handleSelect = (value: SelectTypeItem, index: number) => {
      state.defaultIndex = index
      handleConfirm(value)
    }

    const handleConfirm = (value: any) => {
      emit('update:modelValue', (value as SelectTypeItem).value)
      emit('onConfirm', value as SelectTypeItem)
      state.show = false
    }

    const handleCancel = () => {
      emit('onCancel')
      state.show = false
      state.defaultIndex = 0
    }
    return {
      ...toRefs(state),
      handleSelect,
      handleCancel,
      handleConfirm
    }
  }
})
</script>
<style lang='scss'>
.meta-dropdown__popup {
  padding-bottom: 2vh;
}
</style>

<style lang='scss' scoped>
.meta-dropdown__value {
  display: flex;
  align-items: flex-end;
  line-height: 1.6em;
  font-size: 28px;
  font-weight: 500;
}

.meta-dropdown__text {
  max-width: 195px;
  @include box-clamp(1);
}

.meta-dropdown__picker {

  :deep(.van-picker__toolbar) {
    height: 54px;
    border-bottom: 1px solid #E7E7E7;

    .van-picker__cancel {
      font-size: 18px;
      font-weight: 400;
      padding-left: 30px;
    }

    .van-picker__confirm {
      font-size: 18px;
      font-weight: 400;
      color: #2F2F2F;
      padding-right: 30px;
    }
  }

  :deep(.van-picker-column__item--selected) {
    color: $textActive !important;
    font-weight: 600;
  }
}

.meta-dropdown__select {
  display: flex;
  flex-direction: column;
  padding: 28px;
  max-height: 680px;
  overflow-y: auto;

  .meta-dropdown__select--content {
    margin: 25px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 500;
    color: #2F2F2F;

    &:hover {
      color: $textActive;
    }
  }

  .meta-dropdown__select--active {
    font-size: 28px;
    font-weight: 500;
    color: $textActive;
  }
}
</style>
