import { createI18n } from 'vue-i18n'
import { messages } from './config'
import { getLang } from '@/utils/cookies'

/** 获取当前语言标识, 兼容ios weelife-language/{language} */
export const getLocaleLanguage = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.includes('weelife-language')) {
    const language = userAgent.match(/(weelife-language)\/([\S.]+)/) as RegExpMatchArray
    if (language && language.length > 0) {
      if (language[language.length - 1].includes('weelife-data')) {
        const index = language[language.length - 1].indexOf('weelife-data')
        return index !== -1 ? language[language.length - 1].substring(0, index) : 'en'
      } else {
        return language[language.length - 1]
      }
    } else {
      return 'en'
    }
  } else if (userAgent.includes('weelife-unity')) {
    const language = userAgent.match(/language:([^,]+)/) as RegExpMatchArray
    if (language && language.length > 0) {
      return language[language.length - 1]
    } else {
      return navigator.language.toLowerCase()
    }
  } else if (getLang()) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getLang()!.toLowerCase() || navigator.language.toLowerCase()
  }
  return navigator.language.toLowerCase()
}

export const getTransitionLanguage = (lang?: string) => {
  const language = lang || getLocaleLanguage().toLowerCase()
  const msKeys = ['ms', 'ms-bn', 'ms-my', 'ms-cn', 'ms-sg', 'ms-id', 'ms-au', 'ms-ph']
  const idKeys = ['id', 'id-bn', 'id-my', 'id-cn', 'id-sg', 'id-id', 'id-idr', 'id-idn', 'id-tl', 'in']
  const arKeys = ['ar', 'ar-ae', 'ar-bh', 'ar-dz', 'ar-eg', 'ar-iq', 'ar-jo',
    'ar-kw', 'ar-lb', 'ar-ma', 'ar-om', 'ar-qa', 'ar-sa', 'ar-tn', 'ar-ye']
  const tlKeys = ['tl', 'fil', 'tl-ph', 'fil-ph']
  const versionName = process.env.VUE_APP_NAME || ''
  if (language.indexOf('zh') > -1) {
    if (language.indexOf('tw') > -1 || language.indexOf('hk') > -1 || language.indexOf('hant') > -1) {
      return versionName === 'me' ? 'zh' : 'zh-Hant'
    }
    return 'zh'
  } else if (msKeys.includes(language)) {
    return 'ms'
  } else if (idKeys.includes(language)) {
    return 'in'
  } else if (arKeys.includes(language)) {
    return 'ar'
  } else if (tlKeys.includes(language)) {
    return versionName === 'me' ? 'en' : 'tl'
  } else {
    return 'en'
  }
}

export const getLocale = () => {
  const language = getTransitionLanguage()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language === locale) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // Default language is English
  return 'en'
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLocale(),
  warnHtmlMessage: 'off',
  messages: messages,
  silentTranslationWarn: true
})

export default i18n
