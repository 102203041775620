<template>
  <m-header fixed class="header" :class="{ 'nav-bar_rtl': language === 'ar' }"
    :style="[background ? `background: ${headerBackground}` : 'background: #D1D4F9']">
    <m-row type="flex" align="center" justify="space-between"
      :style="[hamburgerActive === true ? 'border-bottom: 1px solid rgba(255, 255, 255, 0.2)' : `border-bottom: ${headerBorderBottom}`]">
      <m-col span="8" class="header__left">
        <a href="/">
          <img v-if="headerLogo" class="header__left--logo" radius8 :src="require(`../../assets/img/${headerLogo}`)"
            alt="weelife-logo" />
          <img v-if="nameLogo" class="header__left--name" ml9 :src="require(`../../assets/img/${nameLogo}`)"
            alt="weelife-logo" />
        </a>
      </m-col>
      <m-col v-if="headerTabList.length > 0 && isShowTabs" class="header__right" align="middle"
        :class="{ 'min-header__right': headerTabList.length <= 6 }">
        <div class="header__right--tabs">
          <van-tabs :ellipsis="false" v-model:active="headerTabActive" background="transparent" :color="tabColor"
            :title-inactive-color="titleInactiveColor" :title-active-color="titleActiveColor">
            <van-tab v-for="(item, index) in headerTabList" :key="index" :title="item.title" :to="item.to"
              :name="item.name"></van-tab>
          </van-tabs>
          <lang-select v-model="lang">
            <template #content>
              <div class="header__right--language">
                <van-icon :name="require('@/assets/img/recharge/language.svg')" size="1.6em" :color="titleActiveColor">
                </van-icon>
                <span :style="{ color: titleActiveColor, margin: '0px 3px 0px 3px' }">{{ $findLabel(languageList, lang) }}
                </span>
              </div>
            </template>
          </lang-select>
        </div>
      </m-col>
      <m-col v-if="isShowHamburger" span="4" class="header__right" align="middle">
        <div style="width:30%" class="header__hamburger">
          <m-hamburger @click="onClickHamburger" :active="hamburgerActive" :hamburgerColor="hamburgerColor"></m-hamburger>
        </div>
      </m-col>
    </m-row>
  </m-header>
  <van-overlay class="header__overlay" :show="hamburgerActive" @click="onClickOverlay">
    <div class="header__wrapper">
      <div class="header__wrapper--links" v-if="headerTabList.length">
        <template v-for="(item, index) in headerTabList">
          <a :key="index" v-if="!item.isShowMobile" :href="item.to">
            <img v-if="language === 'ar'" class="intoLeft" src="@/assets/img/intoleft.png" alt=""
              style="width: 25px;height: 25px;">
            <span>{{ item.title }} </span>
            <img v-if="language !== 'ar'" class="intoRight" src="@/assets/img/intoright.png" alt=""
              style="width: 25px;height: 25px;">
          </a>
        </template>
        <lang-select v-model="lang">
          <template #content>
            <span class="header__wrapper--language" @click="langSelectActive = true">
              <img v-if="language === 'ar'" class="intoLeft" src="@/assets/img/intoleft.png" alt=""
                style="width: 25px;height: 25px;">
              <span>{{ $findLabel(languageList, lang) }} </span>
              <img v-if="language !== 'ar'" class="intoRight" src="@/assets/img/intoright.png" alt=""
                style="width: 25px;height: 25px;">
            </span>
          </template>
        </lang-select>
      </div>
    </div>
  </van-overlay>
</template>
<script lang="ts">
import { computed, defineAsyncComponent, PropType, Ref, ref, watchEffect } from 'vue'
import { Tab, Tabs, Overlay, Icon } from 'vant'
import { getTransitionLanguage } from '@/locales'
import LangSelect from './lang-select/index.vue'
import { languageList } from './lang-select/data'
import { SelectTypeItem } from '@/types/common'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

interface IHeaderState {
  headerBackground: Ref<string>
  headerTabActive: Ref<number | string>
  hamburgerActive: Ref<boolean>
  onClickHamburger: () => void
  nameLogo: Ref<string>
  language: Ref<string>
  lang: Ref<string>
  languageList: SelectTypeItem[]
  onClickOverlay: () => void
  langSelectActive: Ref<boolean>
}

export interface IHeaderTabItem {
  title: string | number
  to: string
  name?: string | number
  isShowMobile?: boolean
}

export default {
  components: {
    [Tab.name as string]: Tab,
    [Tabs.name as string]: Tabs,
    [Overlay.name as string]: Overlay,
    [Icon.name as string]: Icon,
    MHamburger: defineAsyncComponent(() => import('@/components/hamburger/index.vue')),
    LangSelect
  },
  props: {
    background: {
      type: String as PropType<string>,
      default: ''
    },
    hamburgerColor: {
      type: String as PropType<string>
    },
    isShowHamburger: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    headerLogo: {
      type: String as PropType<string>,
      default: ''
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: 'logo-black.png'
    },
    isShowTabs: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    tabActive: {
      type: [String, Number] as PropType<string | number>,
      default: ''
    },
    tabColor: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    titleInactiveColor: {
      type: String as PropType<string>,
      default: 'rgba(255, 255, 255, 0.4)'
    },
    titleActiveColor: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    headerTabList: {
      type: Array as PropType<IHeaderTabItem[]>,
      default: () => []
    },
    qrCodeLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    qrCodeUrl: {
      type: String as PropType<string>,
      default: window.location.href
    },
    headerBorderBottom: {
      type: String as PropType<string>,
      default: '1px solid rgba(47, 47, 47, 0.2)'
    }
  },
  setup(props: Readonly<{
    background: string
    tabActive: string | number
    headerNameLogo: string
  }>): IHeaderState {
    const hamburgerActive = ref<boolean>(false)
    const headerTabActive = ref<string | number>('')
    const nameLogo = ref<string>('')
    const headerBackground = ref<string>('')
    const lang = ref<string>('')
    const langSelectActive = ref<boolean>(false)

    watchEffect(() => {
      headerBackground.value = props.background
    })

    watchEffect(() => {
      headerTabActive.value = props.tabActive
    })

    watchEffect(() => {
      nameLogo.value = props.headerNameLogo
    })

    const language = computed(() => {
      return getTransitionLanguage()
    })

    useWindowSizeFn(
      () => {
        if (window.innerWidth > 825) {
          hamburgerActive.value = false
        }
      },
      200,
      { immediate: true }
    )

    const onClickHamburger = () => {
      hamburgerActive.value = !hamburgerActive.value
      nameLogo.value = hamburgerActive.value ? 'logo-white.png' : props.headerNameLogo
      headerBackground.value = hamburgerActive.value ? 'none' : props.background
    }

    const onClickOverlay = () => {
      hamburgerActive.value = langSelectActive.value || false
    }

    return {
      headerBackground,
      headerTabActive,
      hamburgerActive,
      onClickHamburger,
      onClickOverlay,
      lang,
      nameLogo,
      language,
      languageList,
      langSelectActive
    }
  }
}
</script>

<style lang="scss" scoped>
.min-header__right {
  margin-right: 12%;
}

.nav-bar_rtl {
  direction: rtl;

  .min-header__right {
    margin-right: 0px !important;
    margin-left: 12% !important;
  }
}

.intoLeft {
  position: absolute;
  left: 18px;
}

.intoRight {
  position: absolute;
  right: 18px;
}

@mixin link {
  height: 105px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #FFFFFF;
  margin: 30px 0px;
  padding: 10px 0px;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-size: 30px;
  font-weight: 500;
  position: relative;
}

@include b(header) {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .van-row {
    padding-top: 16px;
    padding-bottom: 16px;
    flex-wrap: nowrap;
  }

  @include e(left) {
    text-align: center;

    @include m(logo) {
      box-sizing: border-box;
      width: 60px;
      height: 60px;
      border: 1px solid $border-color-base;
    }

    @include m(name) {
      display: inline-block;
      height: 70px;
      width: auto;
      color: #FFFFFF;
      vertical-align: middle;
      font-weight: bold;
    }
  }

  @include e(right) {
    @include m(tabs) {
      max-width: 1350px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    :deep(.van-tab) {
      max-width: 180px !important;
    }

    :deep(.van-tab__text) {
      font-weight: 600;
      font-size: 16px;
    }

    :deep(.van-tabs__line) {
      width: 25px;
      height: 6px;
    }

    @include m(language) {
      margin-left: 45px;
      margin-right: 35px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 26px;
      font-weight: 600;

      &:hover {
        background-color: #9e9e9e !important;

        span {
          color: #FFFFFF !important;
        }
      }

      span {
        max-width: 195px;
        @include box-clamp(1)
      }

    }
  }

  @include e(overlay) {
    z-index: 2008 !important;
    background-color: rgba(0, 0, 0, 0.9);
  }

  @include e(wrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    height: 100vh;
    z-index: 300;

    @include m(links) {
      display: flex;
      flex-direction: column;

      a {
        @include link;

        span {
          width: 100%;
          text-align: center;
        }
      }
    }

    @include m(language) {
      @include link;
    }

    @include m(block) {
      width: 250px;
      height: 250px;
      background-color: #FFFFFF;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 800px) {
  .van-row {
    border-bottom: none !important;
  }
}

@media screen and (max-width: 875px) {
  .van-row {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

@media screen and (max-width: 825px) {
  .van-row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

@media screen and (max-width: 420px) {
  .van-row {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 420px) {
  .van-row--justify-space-between {
    display: flex;
    align-items: center;
  }
}
</style>
