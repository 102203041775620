// vant-ui built-in lang
import vantEnLocale from 'vant/lib/locale/lang/en-US'
import vantZhLocale from 'vant/lib/locale/lang/zh-CN'
import vantZhHkLocale from 'vant/lib/locale/lang/zh-HK'
import vantZhTwLocale from 'vant/lib/locale/lang/zh-TW'

// User defined lang
import enLocale from './lang/en'
import zhLocale from './lang/zh'
import zhHkLocale from './lang/zh-HK'
import zhTwLocale from './lang/zh-TW'
import zhHantLocale from './lang/zh-Hant'
import msLocale from './lang/ms'
import idLocale from './lang/id'
import arLocale from './lang/ar'
import tlLocale from './lang/tl'

export const messages = {
  en: {
    ...enLocale,
    ...vantEnLocale
  },
  zh: {
    ...zhLocale,
    ...vantZhLocale
  },
  'zh-cn': {
    ...zhLocale,
    ...vantZhLocale
  },
  'zh-Hans': {
    ...zhLocale,
    ...vantZhLocale
  },
  'zh-Hant': {
    ...zhHantLocale,
    ...vantZhHkLocale
  },
  'zh-HK': {
    ...zhHkLocale,
    ...vantZhHkLocale
  },
  'zh-TW': {
    ...zhTwLocale,
    ...vantZhTwLocale
  },
  ms: {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-bn': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-my': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-cn': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-sg': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-id': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-au': {
    ...msLocale,
    ...vantEnLocale
  },
  'ms-ph': {
    ...msLocale,
    ...vantEnLocale
  },
  id: {
    ...idLocale,
    ...vantEnLocale
  },
  'id-bn': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-my': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-cn': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-sg': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-id': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-idr': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-idn': {
    ...idLocale,
    ...vantEnLocale
  },
  'id-tl': {
    ...idLocale,
    ...vantEnLocale
  },
  in: {
    ...idLocale,
    ...vantEnLocale
  },
  ar: {
    ...arLocale,
    ...vantEnLocale
  },
  'ar-ae': {
    ...arLocale,
    ...vantEnLocale
  },
  'ar-eg': {
    ...arLocale,
    ...vantEnLocale
  },
  tl: {
    ...tlLocale,
    ...vantEnLocale
  },
  fil: {
    ...tlLocale,
    ...vantEnLocale
  },
  'tl-ph': {
    ...tlLocale,
    ...vantEnLocale
  },
  'fil-ph': {
    ...tlLocale,
    ...vantEnLocale
  }
}
