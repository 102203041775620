export interface errorCodeType {
  /** 错误码 */
  code: string
  /** 错误信息-zh */
  message: string
  /** 错误信息-en */
  enMessage: string
  /** 错误信息-ar */
  arMessage: string
  /** 错误信息-in */
  idMessage: string
  /** 错误信息-ms */
  msMessage: string
  /** 错误信息-zh-Hant */
  zhHantMessage: string
  /** 错误信息-tl */
  tlMessage: string
}

const errorCode: errorCodeType[] = [
  {
    code: '2',
    message: '参数有误',
    enMessage: 'Invalid credentials.',
    arMessage: 'خطأ',
    idMessage: 'Parameternya salah',
    msMessage: 'Parameter tidak betul',
    zhHantMessage: '參數有誤',
    tlMessage: 'Hindi wastong kredensyal'
  },
  {
    code: '2000',
    message: '参数有误',
    enMessage: 'Invalid credentials.',
    arMessage: 'خطأ',
    idMessage: 'Parameternya salah',
    msMessage: 'Parameter tidak betul',
    zhHantMessage: '參數有誤',
    tlMessage: 'Hindi wastong kredensyal'
  },
  {
    code: '2001',
    message: '场景不存在',
    enMessage: 'Scenario does not exist.',
    arMessage: 'المشهد غير موجود',
    idMessage: 'Adegan tidak ada',
    msMessage: 'Adegan itu tidak wujud',
    zhHantMessage: '場景不存在',
    tlMessage: 'Walang ganoong senaryo'
  },
  {
    code: '2002',
    message: '场景已下架',
    enMessage: 'This scenario has been removed.',
    arMessage: 'تمت إزالة المشهد',
    idMessage: 'Adegan telah ditarik dari rak',
    msMessage: 'Tempat kejadian telah dinyahaktifkan',
    zhHantMessage: '場景已下架',
    tlMessage: 'Ang senaryo ay tinanggal na'
  },
  {
    code: '9001',
    message: '服务器开小差, 请重试',
    enMessage: 'Pelayan sepi, sila cuba lagi',
    arMessage: 'السيرفر خارج الخدمة، يرجى المحاولة مرة أخرى',
    idMessage: 'Server diskros, coba lagi',
    msMessage: 'Pelayan sepi, sila cuba lagi',
    zhHantMessage: '伺服器出錯，請重試',
    tlMessage: 'Ang server ay hindi tumutugon, mangyaring subukan muli'
  },
  {
    code: '10004',
    message: '用户登陆失败',
    enMessage: 'User login failure.',
    arMessage: 'فشل تسجيل الدخول',
    idMessage: 'Pendaratan pengguna gagal',
    msMessage: 'Pengguna gagal log masuk',
    zhHantMessage: '用戶登入失敗',
    tlMessage: 'Nabigong mag-log in ang gumagamit'
  },
  {
    code: '10007',
    message: '账号或密码错误',
    enMessage: 'The account or password is incorrect.',
    arMessage: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    idMessage: 'Nomor rekening atau kata sandi yang salah',
    msMessage: 'Nombor akaun atau kata laluan tidak betul',
    zhHantMessage: '帳號或密碼錯誤',
    tlMessage: 'Mali ang account o password'
  },
  {
    code: '10008',
    message: '用户不存在',
    enMessage: 'User does not exist.',
    arMessage: 'المستخدم غير موجود',
    idMessage: 'Pengguna tidak ada',
    msMessage: 'Pengguna tidak wujud',
    zhHantMessage: '用戶不存在',
    tlMessage: 'Ang gumagamit ay hindi umiiral'
  },
  {
    code: '10010',
    message: '邮箱内容发送失败',
    enMessage: 'Failed to send email content.',
    arMessage: 'فشل إرسال محتوى البريد الإلكتروني',
    idMessage: 'Pengiriman pesan gagal',
    msMessage: 'Gagal menghantar kandungan peti mel',
    zhHantMessage: '郵件內容發送失敗',
    tlMessage: 'Nabigong magpadala ng nilalaman ng email'
  },
  {
    code: '10019',
    message: '邮箱修改密码记录不存在',
    enMessage: 'Password change record for this mailbox does not exist.',
    arMessage: 'سجل تغيير كلمة المرور للبريد الإلكتروني غير موجود',
    idMessage: 'Email modifikasi catatan sandi tidak ada',
    msMessage: 'Rekod perubahan kata laluan e-mel tidak wujud',
    zhHantMessage: '郵箱修改密碼記錄不存在',
    tlMessage: 'Walang rekord ng pagbabago ng password para sa mailbox na ito'
  },
  {
    code: '10020',
    message: '重置密码连接已经过期',
    enMessage: 'Password reset connection expired.',
    arMessage: 'إنتهت صلاحية رابط إعادة تعيين كلمة المرور',
    idMessage: 'Reset koneksi kata sandi telah habis',
    msMessage: 'Sambungan set semula kata laluan telah tamat tempoh',
    zhHantMessage: '重置密碼連結已過期',
    tlMessage: 'Ang koneksyon sa pag-reset ng password ay expired na'
  },
  {
    code: '10021',
    message: '密码连接已经被重置过',
    enMessage: 'Password connection reset.',
    arMessage: 'تمت إعادة تعيين رابط كلمة المرور',
    idMessage: 'Koneksi password telah disetel ulang',
    msMessage: 'Sambungan kata laluan telah ditetapkan semula',
    zhHantMessage: '密碼連結已被重置',
    tlMessage: 'Na-reset na ang koneksyon sa password'
  },
  {
    code: '10022',
    message: '重置密码失败',
    enMessage: 'Failed to reset password.',
    arMessage: 'فشل إعادة تعيين كلمة المرور',
    idMessage: 'Gagal untuk mengatur ulang kata sandi',
    msMessage: 'Gagal menetapkan semula kata laluan',
    zhHantMessage: '重置密碼失敗',
    tlMessage: 'Nabigong i-reset ang password'
  },
  {
    code: '10025',
    message: '密码为空',
    enMessage: 'Password is empty',
    arMessage: 'كلمة المرور فارغة',
    idMessage: 'Password kosong',
    msMessage: 'Kata laluan kosong',
    zhHantMessage: '密碼為空',
    tlMessage: 'Walang laman ang password'
  },
  {
    code: '10030',
    message: '在其它设备登录',
    enMessage: 'Login on other devices',
    arMessage: 'تسجيل الدخول من الأجهزة الأخرى',
    idMessage: 'Log masuk di perangkat lainnya',
    msMessage: 'Log masuk pada peranti lain',
    zhHantMessage: '在其他設備上登入',
    tlMessage: 'Nag-log in sa ibang mga device'
  },
  {
    code: '10037',
    message: 'token已过期',
    enMessage: 'Token has expired',
    arMessage: 'token إنتهت الصلاحية',
    idMessage: 'Token telah kedaluwarsa',
    msMessage: 'Token telah tamat tempoh',
    zhHantMessage: 'token已過期',
    tlMessage: 'Ang token ay nag-expire na'
  },
  {
    code: '10039',
    message: '找不到该邀请码信息',
    enMessage: 'The invitation code information could not be found.',
    arMessage: 'لا يمكن العثور على معلومات رمز الدعوة',
    idMessage: 'Tidak dapat menemukan kode undangan',
    msMessage: 'Maklumat kod jemputan tidak dapat dijumpai',
    zhHantMessage: '找不到該邀請碼信息',
    tlMessage: 'Hindi mahanap ang impormasyon ng invitation code'
  },
  {
    code: '10040',
    message: '获取邀请码信息失败',
    enMessage: 'Failed to get invitation code information.',
    arMessage: 'فشل في الحصول على معلومات رمز الدعوة',
    idMessage: 'Dapatkan kode undangan gagal',
    msMessage: 'Gagal mendapatkan maklumat kod jemputan',
    zhHantMessage: '獲取邀請碼信息失敗',
    tlMessage: 'Nabigong makuha ang impormasyon ng invitation code'
  },
  {
    code: '10064',
    message: '用户被封禁',
    enMessage: 'The user is blocked.',
    arMessage: 'تم حظر المستخدم',
    idMessage: 'Pengguna dilarang',
    msMessage: 'Pengguna diharamkan',
    zhHantMessage: '用戶被封禁',
    tlMessage: 'Ang gumagamit ay naka-block'
  },
  {
    code: '10043',
    message: '已经退出登录',
    enMessage: 'Logged out',
    arMessage: 'تم تسجيل الخروج',
    idMessage: 'Sudah keluar masuk',
    msMessage: 'Dilog keluar',
    zhHantMessage: '已經退出登錄',
    tlMessage: 'Nakalog out na'
  },
  {
    code: '10046',
    message: '订单不存在',
    enMessage: 'Order does not exist',
    arMessage: 'الطلب غير موجود',
    idMessage: 'Pesanan tidak ada',
    msMessage: 'Perintah itu tidak wujud',
    zhHantMessage: '訂單不存在',
    tlMessage: 'Walang ganoong order'
  },
  {
    code: '10047',
    message: '创建订单失败',
    enMessage: 'Order creation failure',
    arMessage: 'فشل إنشاء الطلب',
    idMessage: 'Gagal membuat pesanan',
    msMessage: 'Gagal mencipta pesanan',
    zhHantMessage: '創建訂單失敗',
    tlMessage: 'Nabigong lumikha ng order'
  },
  {
    code: '10048',
    message: '订单配置不存在',
    enMessage: 'The order configuration does not exist',
    arMessage: 'الطلب غير موجود',
    idMessage: 'Konfigurasi pesanan tidak ada',
    msMessage: 'Konfigurasi pesanan tidak wujud',
    zhHantMessage: '訂單配置不存在',
    tlMessage: 'Walang ganoong order configuration'
  },
  {
    code: '10091',
    message: '未支付订单数已超20',
    enMessage: 'Unpaid orders have exceeded 20',
    arMessage: 'عدد الطلبات غير المدفوعة تجاوز 20 طلباً',
    idMessage: 'Pesanan tunggal belum dibayar melebihi 20',
    msMessage: 'Bilangan pesanan yang belum dibayar telah melebihi 20',
    zhHantMessage: '未支付訂單數已超過20',
    tlMessage: 'Ang bilang ng mga hindi nabayarang order ay lumampas sa 20'
  },
  {
    code: '70000',
    message: '参数有误',
    enMessage: 'Invalid credentials.',
    arMessage: 'خطأ',
    idMessage: 'Parameternya salah',
    msMessage: 'Parameter tidak betul',
    zhHantMessage: '參數有誤',
    tlMessage: 'Hindi wastong kredensyal'
  },
  {
    code: '70001',
    message: '充值信息不存在或已下架',
    enMessage: 'Recharge information does not exist or has been removed',
    arMessage: 'معلومات الشحن غير موجودة أو تمت إزالتها',
    idMessage: 'Informasi top tidak ada atau keluar dari rak',
    msMessage: 'Maklumat deposit tidak wujud atau telah dinyahaktifkan',
    zhHantMessage: '充值信息不存在或已下架',
    tlMessage: 'Walang ganoong impormasyon sa pag-recharge o tinanggal na'
  }
]

export default errorCode
